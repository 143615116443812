<template>
  <div class="p-4 flex flex-col gap-2">
    <fw-label>{{ $t('flow') }}</fw-label>

    <div v-for="(item, index) in page.flow" :key="`flow-${item.key}-${index}`" class="border-b border-gray-200">
      <p>FROM: {{ item.from_status }}</p>
      <p v-if="item.to?.length">TO STATES: {{ item.to }}</p>
      <p v-if="item.to?.action">TO ACTION: {{ item.action }}</p>

      <p>WHO:</p>

      <div v-if="item.user_keys?.length">
        <div v-for="user_key in item.user_keys" :key="user_key" class="flex justify-between gap-1">
          <fw-person :person="users?.[user_key]"> </fw-person>
          <fw-button
            type="xlight"
            label="Remover"
            @click.native="$emit('flow-update', { flow: item, delete: { user_keys: [user_key] } })"
          >
            <fw-icon-close-circle class="h-5 w-5" />
          </fw-button>
        </div>
      </div>
      <fw-button @click.native="$emit('flow-update', { flow: item, add: true })">add users</fw-button>

      <div v-if="item.user_roles?.length">
        <div v-for="role in item.user_roles" :key="role" class="flex justify-between gap-1">
          <p>{{ role }}</p>
          <fw-button
            type="xlight"
            label="Remover"
            @click.native="$emit('flow-update', { flow: item, delete: { roles: [role] } })"
          >
            <fw-icon-close-circle class="h-5 w-5" />
          </fw-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VERSIONS_STATES } from '@/fw-modules/fw-core-vue/pages/utils/index.js'
export default {
  props: {
    translator: {
      type: Boolean,
      default: false
    },
    page: {
      type: Object,
      default: () => ({})
    },
    version: {
      type: Object,
      default: () => ({})
    },
    users: {
      type: Object,
      default: () => ({})
    },
    myrole: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      states: ['draft', 'review', 'published', 'closed'],
      statesLabes: Object.freeze(VERSIONS_STATES)
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$i18n.locale
    },
    debugMode() {
      return localStorage.getItem('fw-debug') === 'true'
    }
  },
  methods: {}
}
</script>
